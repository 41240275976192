import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import ReactPixel from 'react-facebook-pixel';
import './App.css';
import Success from './Success'
import OrderSession from './OrderSession'
import Home from './Home';
import Share from './Share';
import Suspension from './Suspension'
const logo = 'https://motherearthbrewco.com/wp-content/themes/motherearth/images/logo.svg';
const options = {
    autoConfig: true, 	// set pixel's autoConfig
    debug: true, 		// enable logs
};

ReactPixel.init('693455384370600', undefined, options);
ReactPixel.pageView(); 	
function App() {
  return (
    <Router>
      <div className="App">
        <header className="App-header">
          <img src={logo} className="App-logo" alt="logo" />
        </header>
        <Switch>
          <Route path="/success">
            {/* <Success /> */}
            <Suspension />
          </Route>
          <Route path="/session/:sessionToken">
            {/* <OrderSession /> */}
            <Suspension />
          </Route>
          <Route path="/share">
            {/* <Share /> */}
            <Suspension />
          </Route>
          <Route path="/">
            {/* <OrderSession /> */}
            <Suspension />
          </Route> 
        </Switch>
      </div>
      </Router>
  );
}

export default App;
