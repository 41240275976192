import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography'
import Link from '@material-ui/core/Link'

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    paper: {
      padding: theme.spacing(2),
      textAlign: 'left',
      color: theme.palette.text.secondary,
    },
  }),
);
function Suspension() {
  const classes = useStyles();

  return (
    <Grid item xs={12}>
      <Paper className={classes.paper}>
        <Typography variant="h4" component="h3" paragraph>
          Thanks for visiting Mother Earth!
        </Typography>
        <Typography variant="body1" component="p" paragraph>
          We are very sorry, but delivery service has been suspended at this time.
          </Typography>
        <Typography variant="body1" component="p" paragraph>
          Please follow us on <Link href="https://www.facebook.com/MotherEarthBrewCo">Facebook</Link> for updates and thank you for your continued support.
          </Typography>

        <Typography variant="body1" component="p" paragraph>
          Love, Mom
    <br />
          <br />
          #LoveYourMother
          <br />
          <Link href="https://www.motherearthbrewco.com/">
            Visit our site!
          </Link>
        </Typography>
      </Paper>
    </Grid>
  );
}

export default Suspension;
